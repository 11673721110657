@import '../../themes/themes';

// TODO: once OSP is used in place of the login page, get rid of the styles in this file
.authentication-method i, .authentication-method span {
  vertical-align: middle;
}

.authentication-method i {
  color: #007cd0;
  font-size: 30px;
  margin-right: 5px;
}

.chain-select {
  border-radius: $border-radius;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 5px;
  padding: $link-padding;
  text-decoration: none;

  &:focus,
  &:hover {
    background-color: $link-bg-color-hover;
    color: $link-text-color-hover;
    text-decoration: none;
    outline: none;
  }

  > i,
  > span {
    vertical-align: middle;
  }

  > i {
    font-size: inherit;
    margin-left: 5px;
  }
}

.ias-menu-content {
  span {
    color: #b8b8b8;
    display: block;
    font-size: 12px;
    margin: 5px 0 8px;
  }
}


[dir="rtl"] {
  .authentication-method i {
    margin-left: 5px;
    margin-right: 0;
  }

  .chain-select {
    > i {
      margin-left: 0;
      margin-right: 5px;
    }
  }

}

.ias-login-screen {
  main {
    .ias-login-dialog {
      background-color: $color-white-1;
      border: 1px solid $color-gray-5;
      border-radius: 3px;
      display: flex;
      flex-flow: column nowrap;
      margin: 25px auto;
      max-width: 500px;
      min-height: 275px;

      .ias-login-banner {
        background-color: $color-primary-1;
        color: $color-white-1;
        font-size: 1.2rem;
        height: 50px;
        width: 100%;
      }

      .login-form {
        padding: 40px 50px;
      }

      .footer-content {
        color: #808080;
        font-size: 11px;
        margin: 25px 0 0 0;
        text-align: center;
      }
    }

    @media (max-width: 768px) {
      display: flex;
      min-height: 100%;

      .ias-content-padding {
        padding: 0;
        flex-grow: 1;
      }

      .ias-login-dialog {
        border: 0;
        border-radius: 0;
        margin: 0;
        max-width: 100%;
        min-height: 100%;
        min-width: 330px;

        .login-form {
          flex-grow: 1;
          margin: auto;
        }

        .ias-input-container {
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;

          input {
            max-width: 300px;
          }
        }

        .footer-content {
          margin: 25px 0;
        }
      }
    }
  }
}

footer {
  position: absolute;
  bottom: 0;
}