@import '../../themes/themes';

.languages-list {
  tr {
    td {
      padding-left: 40px;

      bdi {
        line-height: 25px;
        vertical-align: middle;
      }
    }

    &.current {
      color: $link-text-color-hover;

      td {
        padding: $table-cell-padding;
      }
    }

    &:focus, &:hover {
      color: $link-text-color-hover;
      cursor: pointer;
    }
  }
}

[dir="rtl"] {
  .languages-list {
    tr {
      &.current {
        td {
          padding: 0 10px;
        }
      }

      td {
        padding-left: 0;
        padding-right: 40px;
      }
    }
  }
}
