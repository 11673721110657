.otp-verifier {
    display: flex;
    position: relative;
    flex-direction: column;

    .otp-input-wrapper {
        display: flex;
        align-items: flex-end;

        .otp-button {
            margin-bottom: 15px;
            height: 36px;
            overflow: unset;
        }
    }
}