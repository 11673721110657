@import '../../themes/themes';

.authenticator-form {
  margin-top: 15px;

  .directory-data {
    color: black;
    font-size: 16px;
    vertical-align: middle;
  }

  .status-indicator {
    button,
    i {
      flex-shrink: 0;
    }

    font-size: 16px;
    max-width: $max-readable-width;
  }
}

.authenticator-centered {
  display: flex;
  justify-content: center;
}

.authenticator-buttons {
  margin-top: 30px;
}

.authenticator-category {
  label {
    display: inline-block;
    margin-top: 0;
  }
}

.authenticator-section {
  margin-bottom: 15px;
  margin-top: 15px;
}

.ias-button {
  min-width: 62px;
}

.ias-header {
  .primary-icon {
    color: $color-primary-2;
    font-size: 40px;
    margin-right: 5px;
  }

  .primary-icon-button {
    color: $color-gray-4;

    i {
      font-size: 28px;
    }

    &:focus,
    &:hover {
      color: #007cd0;
    }
  }

  .secondary-icon {
    color: $check-enrolled;
    margin-right: 15px;
  }
}

[dir="rtl"] {
  .ias-header {
    .primary-icon {
      margin-left: 5px;
      margin-right: 0;
    }

    .secondary-icon {
      margin-left: 15px;
      margin-right: 0;
    }
  }
}
