.show-hide-password {
  white-space: nowrap;

  .ias-button.ias-icon-button {
    margin-left: 5px;
    padding: 6px;
  }

  i {
    font-size: 20px;
  }
}

[dir="rtl"] {
  .show-hide-password {
    .ias-button.ias-icon-button {
      margin-left: 0;
      margin-right: 5px;
    }
  }
}