@import '../themes/themes';

.loading-indicator {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 50px;          // Hard-coded to height of app-bar
  width: 100%;

  .loading-indicator-content {
    background-color: $body-bg-color;
    border: 4px solid $body-bg-color;
    border-radius: 4px;
    display: inline-block;
    margin: 0 25px;
    max-width: 375px;
    opacity: .95;
    position: relative;
    top: 40%;
    transform: translateY(-40%);

    .ias-status-message {
      margin: 0;
      width: auto;

      img {
        flex-shrink: 0;
        height: 25px;
        margin-right: 5px;
      }

      .ias-status-message-title {
        display: inline-flex;
      }
    }
  }
}

[dir="rtl"] {
  .loading-indicator {
    .loading-indicator-content {
      .ias-status-message {
        img {
          margin-left: 5px;
          margin-right: 0;
        }
      }
    }
  }
}