@import '../../../themes/themes';

.fingerprint-scan-dialog {
  text-align: center;

  .capture-animation {
    height: 100px;
  }

  .description {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .fingerprint-icon {
    color: #808080;
    font-size: 100px;
  }

  .ias-actions {
    margin-top: 20px;
  }

  .mark-icon {
    color: $color-gray-5;
    font-size: 40px;

    &.complete {
      color: $check-enrolled;
    }
  }

  .required-scans-message {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.hand-map {
  margin: 15px 0;
  min-height: 220px;
  position: relative;
  width: $handmap-width-px * 1px;

  &.enable-enrollment {
    &.duress-finger-mode {
      .fingerprint {
        &.enrolled {
          &:focus,
          &:hover {
            color: $color-primary-2;

            .fingerprint-secondary-icon {
              .ias-icon-check_thick {
                color: $check-enrolled;
              }
            }
          }
        }
      }
    }

    &.multifinger-mode {
      .multifinger {
        &.enrolled {
          &:focus,
          &:hover {
            border-color: $color-accent-4;

            .fingerprint {
              color: $color-accent-4;
            }

            .remove-multifinger {
              color: $color-accent-4;
            }
          }

          .remove-multifinger {
            color: transparent;
            position: absolute;
            text-align: center;
          }
        }

        &:focus,
        &:hover {
          border-color: #01a9e7;
          cursor: pointer;

          .fingerprint {
            color: $color-primary-2;

            &.enrolled {
              .fingerprint-secondary-icon {
                .ias-icon-check_thick {
                  color: $check-enrolled;
                }
              }
            }
          }
        }
      }
    }

    &.standard-mode {
      .fingerprint {
        &.enrolled {
          &:focus,
          &:hover {
            color: $color-accent-4;

            .fingerprint-secondary-icon {
              .ias-icon-favorite_fill {
                color: $color-accent-4;
              }

              .ias-icon {
                &:before {
                  content: '\E907';
                }
              }
            }
          }
        }
      }
    }

    .fingerprint {
      &:focus,
      &:hover {
        color: $color-primary-2;
        cursor: pointer
      }
    }
  }

  &.multifinger-mode {
    .multifinger {
      background-color: rgba(128, 128, 128, .1);
      border-color: #b0b0b0;
    }
  }

  img {
    opacity: .35;
    width: 100%;
  }

  .legend {
    color: $color-primary-2;
    text-align: center;

    i,
    span {
      vertical-align: middle;
    }

    i {
      font-size: 22px;
    }

    span {
      margin-left: 5px;
    }
  }

  .fingerprint {
    color: #808080;
    position: absolute;

    &.enrolled {
      color: $check-enrolled;

      .ias-icon-favorite_fill {
        color: $color-primary-2;
      }
    }

    &.scan {
      color: $color-primary-2;
    }

    .fingerprint-icon {
      font-size: 33px;
    }

    .fingerprint-secondary-icon {
      left: 6px;
      position: absolute;
      top: -19px;

      i {
        font-size: 22px;
      }
    }
  }

  .left-hand-text,
  .right-hand-text {
    color: #808080;
    font-size: 13px;
    position: absolute;
    text-align: center;
    top: 220px;
    width: 120px;
  }

  .left-hand-text {
    left: 53px;
  }

  .multifinger {
    position: absolute;
    border: 2px dashed transparent;
  }

  .right-hand-text {
    right: 53px;
  }
}

@media (min-width: 768px) {
  .ias-dialog {
    .ias-dialog-container {
      &.fingerprint-scan-dialog {
        width: 325px;
      }

      &.duress-finger-dialog {
        width: ($handmap-width-px + 40) * 1px;
      }
    }
  }
}

[dir="rtl"] {
  .hand-map {
    .legend {
      span {
        margin-left: 0;
        margin-right: 5px;
      }
    }
  }
}
