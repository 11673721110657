.ias-toast {
  opacity: .9;
  pointer-events: none;
  position: fixed;
  text-align: center;
  transition: opacity 100ms linear;
  width: 100%;
  z-index: 10;

  &.ias-fade-out {
    opacity: 0;
  }

  .ias-status-message {
    display: inline-block;
    margin: 0 25px;
    max-width: 500px;
    pointer-events: auto;
    width: auto;

    button,
    i {
      flex-shrink: 0;
    }

    .close-button {
      margin-left: 15px;
    }

    .ias-status-message-title {
      display: inline-flex;
    }
  }
}

[dir="rtl"] {
  .ias-toast {
    .ias-status-message {
      .close-button {
        margin-left: 0;
        margin-right: 15px;
      }
    }
  }

  // TODO: fix this in ux-ias so we can remove it from here (_status-messages.scss RTL statement should target
  // .ias-status-message-title>.ias-icon not .ias-status-message-title .ias-icon)
  .ias-status-message-title {
    .ias-icon {
      margin-left: 0;
    }

    > .ias-icon {
      margin-left: 5px;
    }
  }
}